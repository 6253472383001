/* eslint-disable import/no-named-as-default-member */
import React, { useEffect, useState } from "react"
import { Field, FormikProvider, useFormik } from "formik"
import { toast } from "react-toastify"
import axios from "axios"
import ReCAPTCHA from "react-google-recaptcha"
import { t } from "i18next"
import ValidationSchema from "./validationSchema"
import EnsInput from "../EnsInput/EnsInput"
import EnsButton from "../EnsButton/EnsButton"
// eslint-disable-next-line import/no-named-as-default
import Map from "../Map/Map"
import "./ElectricUp.scss"
import { useTranslation } from "react-i18next"

// Hook
function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}

function ElectricUp(props) {
  const [csrfToken, setCsrfToken] = useState("")
  const [searchTerm, setSearchTerm] = useState("")
  const debouncedSearchTerm = useDebounce(searchTerm, 1000)
  const [emailError, setEmailError] = useState("")
  const [cuiLoading, setCuiLoading] = useState(false)
  const [cuiError, setCuiError] = useState("")
  const [searchCUI, setSearchCUI] = useState("")
  const debouncedSearchCUI = useDebounce(searchCUI, 1000)
  const [assemblyAddress, setAssemblyAddress] = useState("")
  const [mapError, setMapError] = useState("")
  const { t } = useTranslation();
  const salesApiUrl = process.env.GATSBY_ENSYS_SALES_API ? `${process.env.GATSBY_ENSYS_SALES_API}` : 'https://sales.api.ensys.eu/api'
  const validationSchema = ValidationSchema();
  if (!csrfToken) {
    setCsrfToken(
      fetch(salesApiUrl, {
        method: "GET",
        credentials: "include",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(response => {
          return response.json()
        })
        .then(data => {
          return data
        })
    )
  }

  const formik = useFormik({
    initialValues: {
      geoAddress: "",
      latitude: "",
      longitude: "",
      kWh: "",
      assembly: "",
      seasonality: "Activitate pe tot parcursul anului",
      companyName: "",
      CUI: "",
      address: "",
      lastName: "",
      firstName: "",
      email: "",
      phone: "",
      jobPosition: "",
      message: "",
      recaptcha: "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async values => {
      const csrf = await csrfToken
      const data = {
        geoAddress: assemblyAddress,
        latitude: values.latitude,
        longitude: values.longitude,
        kWh: values.kWh,
        assembly: values.assembly.value,
        seasonality: values.seasonality,
        message: values.message,
        cui: values.CUI,
        address: values.address,
        companyName: values.companyName,
        contact: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phone: values.phone,
          jobPosition: values.jobPosition,
          typeOfContactId: "persoana juridica",
        },
      }
      document.querySelector('.form-button').setAttribute('disabled', 'disabled');
      setTimeout(() => {
        document.querySelector('.form-button').removeAttribute('disabled');
      }, 3000);
      const ensysDB = await fetch(
        `${process.env.GATSBY_ENSYS_SALES_API}/contact-form/electric-up/save`,
        {
          method: "POST",
          credentials: "include",
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "csrf-token": csrf.csrfToken,
          },
          body: JSON.stringify(data),
        }
      )
        .then(response => {
          return response.status
        })
        .catch(response => {
          toast.error(response.message, {
            icon: false,
            theme: "colored",
            position: "top-left",
            autoClose: 7000,
            pauseOnHover: false,
            draggable: false,
            toastId: "contact-toast",
          })
          formik.setSubmitting(false)
          formik.resetForm()
        })
      if (ensysDB === 200) {
        axios
          .post("/api/send-email", {
            SentFrom: "ElectricUp Page",
            ...values,
          })
          .then(response => {
            if (response.status === 200) {
              formik.setSubmitting(false)
              localStorage.setItem("electricup-email-sent", "true");
              toast.success(t("Mesajul a fost trimis"), {
                icon: false,
                theme: "colored",
                position: "top-left",
                autoClose: 7000,
                pauseOnHover: false,
                draggable: false,
                toastId: "contact-toast",
              })
            }
            formik.resetForm()
            window.location.replace("https://electricup.ensys.eu/thank-you")
          })
          .catch(() => {
            toast.error("Am întâmpinat o eroare la trimiterea mesajului", {
              icon: false,
              theme: "colored",
              position: "top-left",
              autoClose: 7000,
              pauseOnHover: false,
              draggable: false,
              toastId: "contact-toast",
            })
            formik.setSubmitting(false)
            formik.resetForm()
          })
      }
    },
  })

  const callAnafApiDebounced = () => {
    if (searchCUI && searchCUI.length > 3) {
      setCuiLoading(t("Preluăm informații de la ANAF"))
      const today = new Date()
      axios
        .post("/api/get-anaf-cui", [
          {
            cui: Number(searchCUI),
            data: today.toISOString().split("T")[0],
          },
        ])
        .then(response => {
          if (response && response.data && response.data.found.length) {
            if (response.data.found[0].date_generale) {
              const found = response.data.found[0].date_generale
              if (found.denumire && found.adresa) {
                formik.setFieldValue("address", found.adresa)
                formik.setFieldValue("companyName", found.denumire)
                setCuiLoading("")
                setCuiError("")
              } else {
                setCuiError(t("CUI invalid"))
              }
            } else {
              setCuiError(t("CUI invalid"))
            }
          } else {
            setCuiError(t("CUI invalid"))
          }
        })
        .catch(err => {
          console.log("anaf api error-->", err)
        })
    }
  }

  const chooseMessage = coordinates => {
    formik.setFieldValue("latitude", coordinates.lat)
    formik.setFieldValue("longitude", coordinates.lng)
    setAssemblyAddress(coordinates.address)
  }

  useEffect(() => {
    setSearchCUI(formik.values.CUI)
  }, [formik.values.CUI])

  // Effect for API call
  useEffect(
    () => {
      callAnafApiDebounced()
    },
    [debouncedSearchCUI] // Only call effect if debounced search term changes
  )

  const callCheckEmailDebounced = () => {
    if (debouncedSearchTerm && debouncedSearchTerm.length > 6) {
      axios
        .post("/api/validate-email", {
          source: "electricUp",
          email: debouncedSearchTerm,
        })
        .then(response => {
          setEmailError(t("Email invalid"))
          if (response && response.data && response.data.result) {
            if (
              response.data.result.verdict === "Valid" ||
              response.data.result.verdict === "Risky"
            ) {
              formik.setFieldError("email", null)
              setEmailError("")
            }
          }
        })
        .catch(err => console.log("email validation error-->", err))
    }
  }

  useEffect(() => {
    setSearchTerm(formik.values.email)
  }, [formik.values.email])

  // Effect for API call
  useEffect(
    () => {
      callCheckEmailDebounced()
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  )

  useEffect(() => {
    if (formik.errors.latitude) {
      setMapError(
        t("Vă rugăm alegeți o adresă sau mutați marker-ul pe locația dorită")
      )
    }
  }, [formik.errors.latitude])

  return (
    <div className="container">
      <div className="electric-up">
        <div className="row">
          <div className="col-md-2" />
          <div className="col-md-8">
            <h1>{t("Formular de înscriere ElectricUp")}</h1>
            <div>
              <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                  <p style={{ marginTop: "3rem" }}>
                    {t("Informații societate")}
                  </p>
                  {cuiLoading && (
                    <p
                      style={{
                        color: "blue",
                        fontStyle: "italic",
                        marginTop: "-2rem",
                      }}
                    >
                      {cuiLoading}
                    </p>
                  )}
                  <Field
                    style={{ marginTop: "3rem" }}
                    name="CUI"
                    variant="text"
                    label={t("CUI")}
                    component={EnsInput}
                    id={1}
                  />
                  {cuiError && (
                    <p
                      style={{
                        color: "red",
                        fontStyle: "italic",
                        marginTop: "-2rem",
                      }}
                    >
                      {cuiError}
                    </p>
                  )}
                  <Field
                    name="companyName"
                    variant="text"
                    label={t("Denumire societate")}
                    component={EnsInput}
                    id={2}
                  />
                  <Field
                    name="address"
                    variant="text"
                    label={t("Adresă fiscală")}
                    component={EnsInput}
                    id={3}
                  />
                  <p style={{ marginBottom: "10rem" }}>
                    {t("Informații despre adresa de implementare")}
                  </p>
                  {mapError && (
                    <p
                      style={{
                        color: "red",
                        fontStyle: "italic",
                        marginTop: "-2rem",
                        marginBottom: "7rem",
                      }}
                    >
                      {mapError}
                    </p>
                  )}
                  <div style={{ marginBottom: "3rem" }}>
                    <Map
                      chooseMessage={chooseMessage}
                      // eslint-disable-next-line react/prop-types, react/destructuring-assignment
                      google={props.google}
                      center={{
                        lat: 47.06292759999999,
                        lng: 21.9331372,
                      }}
                      height="400px"
                      zoom={15}
                    />
                  </div>
                  <Field
                    style={{ display: "none" }}
                    name="latitude"
                    as="input"
                    label="latitude"
                    placeholder={t("Latitudine")}
                    id={10}
                  />
                  <Field
                    style={{ display: "none" }}
                    name="longitude"
                    variant="text"
                    label="longitude"
                    placeholder={t("Longitudine")}
                    id={11}
                  />
                  <Field
                    style={{ marginTop: "9rem" }}
                    name="kWh"
                    type="number"
                    label={t("Consum total (în kWh/an)")}
                    component={EnsInput}
                    id={12}
                  />
                  <Field
                    name="assembly"
                    variant="select"
                    options={[
                      {
                        value: "acoperis drept",
                        label: t("Acoperiș drept"),
                      },
                      { value: "inclinare mica", label: t("Înclinație mică") },
                      { value: "inclinare medie", label: t("Înclinație medie") },
                      { value: "la sol", label: t("La sol") },
                      { value: "nu stiu", label: t("Nu știu") },
                    ]}
                    component={EnsInput}
                    label={t("Amplasarea panourilor")}
                    id={13}
                  />
                  <p>{t("Sezonabilitate")}</p>
                  <div className="custom-control">
                    <Field
                      component="div"
                      name="seasonality"
                      className="radioInputs"
                    >
                      <div>
                        <input
                          type="radio"
                          id="ActivitateSezonieraAnIntreg"
                          name="seasonality"
                          value="Activitate pe tot parcursul anului"
                          checked={
                            formik.values.seasonality ===
                            "Activitate pe tot parcursul anului"
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="ActivitateSezonieraAnIntreg"
                        >
                          {t("Activitate pe tot parcursul anului")}
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="ActivitateSezonieraSaseLuni"
                          name="seasonality"
                          value="Activitate sezonieră (6 luni)"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="ActivitateSezonieraSaseLuni"
                        >
                          {t("Activitate sezonieră (6 luni)")}
                        </label>
                      </div>
                    </Field>
                  </div>
                  <p style={{ marginTop: "3rem" }}>
                    {t("Persoană de contact")}:
                  </p>
                  <div className="optional-fields">
                    <Field
                      name="lastName"
                      variant="text"
                      label={t("Nume de familie")}
                      component={EnsInput}
                      id={4}
                    />
                    <Field
                      name="firstName"
                      variant="text"
                      label={t("Prenume")}
                      component={EnsInput}
                      id={5}
                    />
                    <Field
                      name="jobPosition"
                      variant="text"
                      label={t("Funcție deținută în firmă")}
                      component={EnsInput}
                      id={6}
                    />
                    <Field
                      name="email"
                      variant="email"
                      label={t("Adresă de email")}
                      component={EnsInput}
                      id={7}
                    />
                    {emailError && formik.values.email && (
                      <p
                        style={{
                          color: "red",
                          fontStyle: "italic",
                          marginTop: "-2rem",
                        }}
                      >
                        {emailError}
                      </p>
                    )}
                    <Field
                      name="phone"
                      variant="tel"
                      label={t("Telefon")}
                      component={EnsInput}
                      id={8}
                    />
                    <Field
                      name="message"
                      variant="textarea"
                      label={t("Mesaj")}
                      component={EnsInput}
                      id={9}
                    />
                  </div>
                  <ReCAPTCHA
                    name="recaptcha"
                    sitekey="6LeRlA0oAAAAANQZT1gzZcmdilFY-XV4974Pkz37"
                    onChange={response => {
                      formik.setFieldValue("recaptcha", response)
                    }}
                    className="recaptcha-validation"
                  />
                  {formik.errors.recaptcha && formik.touched.recaptcha && (
                    <p
                      style={{
                        color: "red",
                        fontStyle: "italic",
                      }}
                    >
                      {formik.errors.recaptcha}
                    </p>
                  )}
                  <EnsButton
                    variant="primary"
                    type="submit"
                    className="form-button"
                    disabled={!!emailError || !!cuiError}
                  >
                    {t("Trimite")}
                  </EnsButton>
                </form>
              </FormikProvider>
            </div>
          </div>
          <div className="col-md-2" />
        </div>
      </div>
    </div>
  )
}

export default ElectricUp
